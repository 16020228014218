<script>
import Vue, { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import DrawerBankForm from '/~/components/drawer/components/payment-methods/forms/drawer-bank-form.vue'
import DrawerCardForm from '/~/components/drawer/components/payment-methods/forms/drawer-card-form.vue'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

export default {
  name: 'drawer-payment-methods-add',
  components: {
    DrawerCardForm,
    DrawerBankForm,
    BaseTabs,
    BaseTab,
    BaseAsidePage,
    BaseLoader,
  },
  props: {
    title: {
      type: String,
      default: 'Add Payment Method',
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {
      isMethodAvailable,
      creating,
      everyBankAccountPayable,
      fetchingList: fetching,
      configLoading,
    } = usePaymentMethods()
    const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()
    const { hideEwalletMenu } = useUI()

    const tab = ref('card')

    function isShowMethod(type) {
      return isMethodAvailable(type, router.currentRoute.params.source)
    }

    const loading = computed(() => configLoading.value || fetching.value)
    const showBankTab = computed(
      () =>
        everyBankAccountPayable.value &&
        isShowMethod(PaymentMethodType.bankAccount)
    )
    const showCardTab = computed(() =>
      isShowMethod(PaymentMethodType.creditCard)
    )
    const hasAvailableMethods = computed(
      () => showBankTab.value || showCardTab.value
    )

    async function onAdded(paymentMethod) {
      let text = 'Credit card successfully added'

      if (paymentMethod.type === PaymentMethodType.bankAccount) {
        text = 'Bank account successfully added'
      }

      if (
        paymentMethod.type === PaymentMethodType.bankAccount &&
        isBankAccountsNavEnabled.value
      ) {
        Vue.notify({
          text: 'Your bank account verification has been initiated and an amount between $0.01 and $2.99 will appear on your statement. Please allow up to 24 hours and come back once you have located this amount in order to verify your Bank Account.',
          type: 'success',
          duration: 10000,
        })
      } else {
        Vue.notify({
          text,
          type: 'success',
          duration: 3000,
        })
      }

      await router.push({
        hash: '#profile-payment-methods',
        query: route.query,
      })

      if (isCreditCardsNavEnabled.value || isBankAccountsNavEnabled.value) {
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: paymentMethod,
          },
        })
      } else {
        if (
          [
            'purchase-checkout-payment-methods',
            'quick-buy-payment-methods',
            'statements-method',
            'payments-method',
          ].includes(route.name)
        ) {
          hideEwalletMenu()
        }
      }
    }

    onMounted(() => {
      tab.value = showCardTab.value ? 'card' : 'bank'
      emitter.on('payment-methods:created', onAdded)
    })

    onBeforeUnmount(() => {
      emitter.off('payment-methods:created', onAdded)
    })

    return {
      isShowMethod,
      loading,
      creating,
      tab,
      hasAvailableMethods,
      showBankTab,
      showCardTab,
    }
  },
}
</script>

<template>
  <base-aside-page :title="title" no-padding>
    <base-loader v-if="loading" class="py-[30px]" fullwidth />
    <base-tabs
      v-else-if="hasAvailableMethods"
      v-model="tab"
      :disabled="creating"
      class="h-full"
    >
      <base-tab
        v-if="showCardTab"
        name="Credit/Debit Card"
        value="card"
        class="px-5"
      >
        <drawer-card-form />
      </base-tab>

      <base-tab
        v-if="showBankTab"
        name="Bank Account"
        value="bank"
        class="px-5"
      >
        <drawer-bank-form />
      </base-tab>
    </base-tabs>
    <div v-else class="p-[30px] text-center font-bold">
      No payment methods available
    </div>
  </base-aside-page>
</template>
